/**
 * Do not edit this file! 
 * You should not write CSS directly when using React Native.
 * We are using CSS resets here to support React Native for Web and Tailwind CSS.
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

/**
   * Building on the RNWeb reset:
   * https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/StyleSheet/initialRules.js
   */
html,
body,
#__next {
    width: 100%;
    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;
    margin: 0px;
    padding: 0px;
    /* Allows content to fill the viewport and go beyond the bottom */
    min-height: 100%;
}

#__next {
    flex-shrink: 0;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    display: flex;
    flex: 1;
}

html {
    /* Prevent text size change on orientation change https://gist.github.com/tfausak/2222823#file-ios-8-web-app-html-L138 */
    -webkit-text-size-adjust: 100%;
    height: 100%;
}

body {
    display: flex;
    /* Allows you to scroll below the viewport; default value is visible */
    overflow-y: auto;
    overscroll-behavior-y: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: scrollbar;
}

/* for OTP web input https://stackoverflow.com/questions/71296535/how-to-remove-arrow-on-input-type-number-with-tailwind-css */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.font-body {
    font-family:
        degular-text,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Helvetica,
        Arial,
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol";
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.font-header {
    font-family: swear-display, serif;
    font-weight: 600;
    font-style: normal;
}
h1,
.h1 {
    @apply text-4xl;
}
h2,
.h2 {
    @apply text-3xl;
}
h3,
.h3 {
    @apply text-2xl;
}
h4,
.h4 {
    @apply text-xl;
}
h5,
.h5 {
    @apply text-lg;
}
h6,
.h6 {
    @apply text-base;
}

/** One way to allow different sized text from the CMS 
 without having to break it out into multiple lines */
.font-headings-normal h1,
.font-headings-normal h2,
.font-headings-normal h3,
.font-headings-normal h4,
.font-headings-normal h5,
.font-headings-normal h6 {
    font-family: degular-text, sans-serif;
}

li {
    margin-left: 16px;
    list-style-type: disc;
}

.sr-only {
    display: none;
}
